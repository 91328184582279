import React from 'react';
import './SearchbarNav.css';

import Input from '../Input/Input';
// import { FontAwesomeIcon } from './node_modules/@fortawesome/react-fontawesome';
// import { faBars } from './node_modules/@fortawesome/free-solid-svg-icons';


const searchbarNav = (props) =>{

    

    return (
        <div className='SearchbarNav'>
            <div className='searchBarMadin'>
                <div className='searchBarDiv'>
                    <select name="searchDropdownEl" className="searchDropdownEl">
                        <option value="all">All</option>
                        <option value="myMusic">My Music</option>
                        <option value="library">Library</option>
                    </select>
                    <input className='searchBar' type="text" disabled placeholder="Search bar coming soon..."/>
                </div>
                <div className='linksDiv disp-flex justify-content--end align-items--center'>
                    <div className='hide-tablet'>
                        <a href="./diagnostics" className='diagnosticsLink' hidden>Workstation</a>
                        <a href="./my-music" className='myMusicLink'>My Music</a>
                        <a href="./library" className='libraryLink'>Library</a>
                        <a href="./profile" className='profileLink'>Profile</a>
                    </div>
                    <div>
                        <Input
                            inputtype='input'
                            type='file'
                            name='uploadMusic'
                            id='uploadMuisc'
                            onChange={props.changed}
                            filelabelspan={props.uploadMusic1}
                            filelabel={props.uploadMusic2}/>
                    </div>
                   
                    {/* <input type="file" name="uploadMusic" id="uploadMusic" className="inputfile" data-multiple-caption="{count} files selected"/>
                    <label htmlFor="uploadMusic"><span>Upload Music</span></label> */}
                </div>   
            </div>
        </div>
    )
}

export default searchbarNav;