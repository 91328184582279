import React, {Component} from 'react';
import './Signup.css';

class Signup extends Component{
    state={
      
    }

    componentDidMount(){

    }

   

    render(){
        console.log('======================', this.state);

    
        return (
            <div className='Signup'>
                <h1>Signup page</h1>
                
            </div>
        )
    }
}

export default Signup;