import React from 'react';
import './Alert.css';

const alert = (props) =>{

    let btnClass = '';
    if(props.color === 'warning'){
        btnClass='Alert warning';
    }
    else if(props.color === 'danger'){
        btnClass = 'Alert danger';
    }


    return(
        <div className='Alert'>
            <div className={btnClass}>
                {props.children}
            </div>
        </div>
    )
}

export default alert;