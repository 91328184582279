import React, {Component} from 'react';
import './Login.css';
import kenaLogo from '../../assets/logo.png';
import config from '../../config';
import { 
    CognitoUserPool, 
    AuthenticationDetails, 
    CognitoUser,
    CognitoUserSession,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import bgImage from '../../assets/bg2.png';

import Navbar from '../../componentes/Navbar/Navbar';
import Input from '../../componentes/Input/Input';
import Button from '../../componentes/Button/Button';
import Alert from '../../componentes/Alert/Alert';

class Login extends Component{
    state={
      showMobileNavbar: false,
      loginErrorMessage: '',
      'loginEmail': '',
      'loginPassword': '',
    }

    componentDidMount(){
       
    }

    toggleMobileNavbar = () =>{
        this.setState({
            showMobileNavbar: !this.state.showMobileNavbar
        });
    }

    inputHandler =(ev, type) =>{
        if(type === 'loginEmail'){
            this.setState({
                'loginEmail': ev.target.value
            })
        }
        else if(type === 'loginPassword'){
            this.setState({
                'loginPassword': ev.target.value
            })
        }
    }

    formLoginHandler = () =>{
        const email = this.state.loginEmail;
        const password = this.state.loginPassword;

        const dataObj = {
            email: email,
            password: password
        }

        if(email && password){
            // $('.message').hide();
            
            // Use case 4. Authenticating a user and establishing a user session with the Amazon Cognito Identity service.
            // (signin)------------------------------------------------------
            const authenticationData = {
                Username: dataObj.email,
                Password: dataObj.password,
            };
            const authenticationDetails = new AuthenticationDetails(
                authenticationData
            );
            const poolData = {
                UserPoolId: config.pool_data.UserPoolId, // Your user pool id here
                ClientId: config.pool_data.ClientId, // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);

            var userData = {
                Username: dataObj.email,
                Pool: userPool,
            };

            var cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function(result) {

                     // get token
                    userPool.getCurrentUser().getSession(function(err, session){
                        if(err){
                            alert('error: session expired');
                        }
                        else{
                            window.location.href = './my-music';
                        }
                    })

                },

                onFailure: function(err) {
                    alert(err.message || JSON.stringify(err));
                },
                // end (signin-----------------------------------------)
            });

        }
        else{
            this.setState({
                loginErrorMessage: 'Please fill out all fields'
            })
        }
    }
   

    render(){
        console.log('======================', this.state);

        let loginErrorMessage = null;
        if(this.state.loginErrorMessage){
            loginErrorMessage = <Alert color='danger'>{this.state.loginErrorMessage}</Alert>;
        }
    
        return (
            <div className='Login'>
                <img src={bgImage} alt="background image" id='bgImage' className='hide-tablet'/>
                    
                <section>
                    <Navbar
                        showMobileNavbar={this.state.showMobileNavbar}
                        clicked={this.toggleMobileNavbar}/>

                    <div className='disp-flex' style={{padding: 0 +20+'px'}}>
                        <div className='flex-50'>
                            <div style={{paddingTop:120+'px'}}></div>

                            <div style={{padding: 30+'px'+ 0 +10+'px'+ 0}}>
                                <h1 className='title'>Kena Opus</h1>
                                <div className='version'>Version 0.1 (alpha)</div>
                            </div>


                            <div className='padding-tb-1'>
                                <input type="text"
                                    value={this.state.login_email}
                                    onChange={(ev)=>this.inputHandler(ev, 'loginEmail')}
                                    placeholder='Email'
                                    className='inputField'/>
                            </div>   

                            <div className='padding-tb-1'>
                                <input type="password"
                                    value={this.state.login_password}
                                    onChange={(ev)=>this.inputHandler(ev, 'loginPassword')}
                                    placeholder='Password'
                                    className='inputField'/>
                            </div>   

                            <div className='text-align-right padding-tb-1'>
                                <a className='link' href="./forgotPassword.html">Forgot password</a>
                            </div>

                            {loginErrorMessage}

                            <div className='padding-tb-1'>
                                <Button
                                    onClick={this.formLoginHandler}
                                    type='button'
                                    color='primary'
                                    className='loginButton'
                                >Login</Button>
                            </div>

                            <div className='text-align-center padding-tb-1'>
                                <a className='link' href="./signup.html">Or Sign Up</a>
                            </div>
                        </div>
                        <div className="flex-50"></div>
                    </div>

                    

                </section>
            </div>
        )
    }
}

export default Login;