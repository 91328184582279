import React, {Component} from 'react';
import './Mymusic.css';
import kenaLogo from '../../assets/logo.png';
import config from '../../config';
import axios from 'axios';
import { 
    CognitoUserPool, 
    AuthenticationDetails, 
    CognitoUser,
    CognitoUserSession,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import bgImage from '../../assets/bg2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import produce from 'immer';
import CryptoJS from 'crypto-js';

import Navbar from '../../componentes/Navbar/Navbar';
import Input from '../../componentes/Input/Input';
import Button from '../../componentes/Button/Button';
import SearchbarNav from '../../componentes/SearchbarNav/SearchbarNav';
import Music from '../../componentes/Music/Music';

class Mymusic extends Component{
    state={
        userId: '',
        myMusic: [],
        uploadMusic1: 'Upload Music',
        uploadMusic2: 'Upload Music',
        loadingIcon: false,
        showMobileNavbar: false,
        playPauseAudio: {
            musicId: null,
            fileUrl: null,
            playing: false,
        }
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleOutsideClick);

        // const ajaxData = {
        //     "user_id": userId,
        //     'page_no': 0,
        //     'stage': config.stage,
        // }

        // axios({
        //     url: config.apiGateway.get_music_list,
        //     method: 'post',
        //     data: ajaxData,
        //     headers: {'Authorization': session.getIdToken().getJwtToken()}, 
        // }).then(response =>{
        // console.log('success response: ', response);
        // }).catch(err =>{
        //     console.log('err: ', err);
        // });
        
        const poolData = {
            UserPoolId: config.pool_data.UserPoolId, // Your user pool id here
            ClientId: config.pool_data.ClientId, // Your client id here
        };

        var userPool = new CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();

        console.log('cognito user: ', cognitoUser);

        if (cognitoUser != null) {
            // loading icon-----------------------------------
            this.setState({
                loadingIcon: true
            });

            // save userid
            const userId = cognitoUser.username;
            this.setState({
                userId: userId
            });

            // get token
            this.getAuthenticatedUser().getSession(function(err, session){
                if(err){
                    alert('error: session expired');
                }
                else{
                    // check if user exists on db-------------------------------
                    const checkUserInDbAjaxData = {
                        "userId": userId,
                        "stage": config.stage,
                    }

                    axios({
                        url: config.apiGateway.checkfirsttimelogin,
                        method: 'post',
                        data: JSON.stringify(checkUserInDbAjaxData),
                        contentType: "application/json",
                        headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                    }).then(response =>{
                        console.log('success check first time login: ', response);
                        // need to check if user logged in for the first time
                        if(response.data.Item){
                            console.log('found user in db');
                        }
                        else{
                            console.log('did not find user in db');

                            // save user to user table--------------------------------
                            // get promocode
                            let localStorageData = '';
                            let promoCode = null;
                            if(localStorage.getItem('kenaPromoData') !== null){
                                localStorageData = JSON.parse(localStorage.getItem('kenaPromoData'));
                                promoCode = localStorageData.promoCode;
                            }

                            console.log('ls data: ', localStorageData);
                            console.log('promocode: ', promoCode);

                            let userData = {
                                'userId': userId,
                                'created_at': Date.now(),   // UTC timestamp in milliseconds
                                'promoCode': promoCode,
                                'stage': config.stage,
                            };

                             axios({
                                url: config.apiGateway.save_user_to_user_table,
                                method: 'post',
                                data: userData,
                                contentType: "application/json",
                                headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                            }).then(response =>{
                                console.log('succeeded saved user to user table');  
                                console.log('response', response);  
    
                                // empty local storage promo code
                                const storeData = {
                                    'promoCode': null
                                }
                                
                                localStorage.setItem('kenaPromoData', JSON.stringify(storeData)); 
                                             
                            }).catch(err =>{
                                console.log('err: ', err);
                            });
                        }

                    }).catch(err =>{
                        console.log('err: ', err);
                    }); 

                    // get genre etc-----------------------------------------------------------
                    const ajaxDataGetGenreEtc = {
                        'stage': config.stage,
                    }

                    axios({
                        url: config.apiGateway.get_genre_etc,
                        method: 'post',
                        data: JSON.stringify(ajaxDataGetGenreEtc),
                        contentType: "application/json",
                        headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                    }).then(response =>{
                        console.log('succeeded get gereEtc');  
                        console.log('response: ', response);

                        const genreEtc = {};
                        genreEtc.musicScale = JSON.parse(response.data.musicScale);
                        genreEtc.genre = JSON.parse(response.data.genre);
                        genreEtc.instrument = JSON.parse(response.data.instrument);
                        genreEtc.mood = JSON.parse(response.data.mood);
                        genreEtc.musicKey = JSON.parse(response.data.musicKey);
                        console.log('genreEtc: ', genreEtc);

                        this.setState({
                            genreEtc: genreEtc
                        });

                        const ajaxData = {
                            "user_id": userId,
                            'page_no': 0,
                            'stage': config.stage,
                        }

                        // check email
                        if(userId === ajaxData.user_id){
                            console.log('passed email check');
                            axios({
                                url: config.apiGateway.get_music_list,
                                method: 'post',
                                data: JSON.stringify(ajaxData),
                                headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                            }).then(response =>{
                                console.log('succeeded loading data on page load');  
                                console.log('response: ', response);

                                const myMusic = response.data.music_info_list;

                                // check if music array is not empty
                                if(myMusic.length){

                                    // sort the list by time
                                    myMusic.sort(function(a, b){return b.created_at - a.created_at});

                                    this.setState({
                                        myMusic: myMusic,
                                        loadingIcon: false
                                    });

                                    console.log('sorted myMusic: ', myMusic);
                        
                                    // // update UI-----------------------
                                    // renderMyMusicList();
                                    // renderProgreeBar();
                                    // playPauseAudio();
                                    // checkDiagnosticsProcessing();
                                }
                                else{
                                    // $('#myMusicList').html(tutorialStepsHtml);
                                }
                            }).catch(err =>{
                                console.log('err: ', err);
                            });
                        }


                    }).catch(err =>{
                        console.log('err: ', err);
                    });
                   
                }
            }.bind(this));

        }
        else{
            window.location.href = './index.html?v=17';
        }
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleOutsideClick);
    } 

    handleOutsideClick = (e) =>{
        // clicked navbar?
        if(this.navbarNode.contains(e.target)){
            
        }
        else{
            console.log('outside navbar');
            this.setState({
                showMobileNavbar: false
            });
        }

        // clicked p?
        if(this.pNode.contains(e.target)){
            
        }
        else{
            console.log('outside p');
        }
    }

    getAuthenticatedUser = () =>{
        const poolData = {
            UserPoolId: config.pool_data.UserPoolId, // Your user pool id here
            ClientId: config.pool_data.ClientId, // Your client id here
        };

        var userPool = new CognitoUserPool(poolData);
        return userPool.getCurrentUser();
    }

    checkLoggedInemailIsSameAsSendingRequest = (snedingEmail) =>{
        // console.log('sendingmeial: ', snedingEmail);
        const userId = this.state.userId;
        // console.log('userid: ', userId);
        if(userId == snedingEmail){
            return true;
        }
        else{
            return false;
        }
    }

    toggleMobileNavbar = () =>{
        this.setState({
            showMobileNavbar: !this.state.showMobileNavbar
        });
    }

    uploadMusicHandler = (ev) =>{
        console.log('type: ', ev.target.type);
        if(ev.target.type && ev.target.type === 'file'){
            // display file name when file was selected
            const label = ev.target.nextElementSibling;
            const labelVal = label.innerHTML;

            let fileName = '';
            if(ev.target.files && ev.target.files.length > 1){
                fileName = (ev.target.getAttribute('data-multiple.caption') || '').replace('{count}', ev.target.files.length);
            }
            else{
                fileName = ev.target.value.split('\\').pop();

                console.log('e target files[0]: ', ev.target.files[0]);
                console.log('file name: ', ev.target.files[0].name);
                console.log('file type: ', ev.target.files[0].type);
                console.log('file size: ', ev.target.files[0].size/1024/1024 + 'MB');
            }

            if(fileName){
                const span = ev.target.name +1;
                console.log('span state: ', span);
                this.setState({ [span]: fileName });

                // file extension and size vertification
                const fileNameSplit =  ev.target.files[0].name.split('.');
                let fileExtension = fileNameSplit[fileNameSplit.length -1];
                fileExtension = fileExtension.toLowerCase();
                const fileSize = Math.round(ev.target.files[0].size/1024/1024);

                const userId = this.state.userId;
                console.log('ext, size: ', fileExtension + ", ", fileSize);
                console.log('filenamesplit[0]: ', fileNameSplit[0]);
                console.log('userid: ', userId);

                let isAcceptableExtension = null;
                if(fileExtension == "mp3" || fileExtension == "m4a" || fileExtension == "ogg" || fileExtension == "wav"){
                    isAcceptableExtension = true;
                }
                else{
                    // if wong extansion
                    alert('Max file size : 2MB. Supported extensions: mp3, m4a, ogg, wav');
                }

                // check file extension and size (2MB)
                if(isAcceptableExtension && fileSize < 2){
                    console.log('file size and ext is good');

                    // loading icon------------------------------------
                    this.setState({
                        loadingIcon: true,
                    });

                    // base64--------------------------------------------
                    // testing api post
                    let userData = {
                        'userId': userId,
                        'fileName': fileNameSplit[0],
                        'created_at': Date.now(),   // UTC timestamp in milliseconds
                        'stage': config.stage,
                    };

                    const userFile = ev.target.files[0];

                    console.log('userdata: ', userData);

                    const that = this;

                    // get binary string-----------------------------------------
                    (function handleFileSelect(evt) {
                        var f = userFile; // FileList object
                        var reader = new FileReader();
                        // Closure to capture the file information.
                        reader.onload = (function(theFile) {
                            return function(e) {                                       

                                var binaryData = e.target.result;
                                //Converting Binary Data to base 64
                                var base64String = window.btoa(binaryData);
                                userData.user_file = base64String;

                                console.log('base64string: ', base64String);

                                // get token--------------------------------------------------
                                console.log('that', that);
                                that.getAuthenticatedUser().getSession(function(err, session){
                                    if(err){
                                        alert('error: session expired');
                                    }
                                    else{

                                        // check email
                                        if(that.checkLoggedInemailIsSameAsSendingRequest(userData.userId)){

                                             axios({
                                                // url: config.apiGateway.uploadfile,
                                                method: 'post',
                                                data: JSON.stringify(userData),
                                                contentType: 'application/json',
                                                headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                                            }).then(response =>{
                                                console.log('success response: ', response);

                                            }).catch(err =>{
                                                console.log('err: ', err);
                                            });

                                        }
                                    }
                                })

                            };
                        })(f);
                        // Read in the image file as a data URL.
                        reader.readAsBinaryString(f);
                    })();

                }
            }
            else{
                const label = ev.target.name +2;
                this.setState({[label]:labelVal});
            }
        }
    }

    audioEl = document.createElement('audio');

    playPauseAudio = (newMusicId, newFileUrl) =>{
        // let currentPlayingId = null;

        console.log('test');
        console.log('newMusicId: ', newMusicId);
        console.log('old musicid: ', this.state.playPauseAudio.musicId);
        console.log('newFileUrl: ', newFileUrl);

        // check if user wants to play different music
        if(this.state.playPauseAudio.musicId === newMusicId){
            console.log('same music id');
            console.log('t f: ', this.audioEl.paused);
            if(this.audioEl.paused){
                console.log('music paused');
                // play music
                this.audioEl.play();
                // update UI
                const newPlayPauseAudio = {...this.state.playPauseAudio};
                newPlayPauseAudio.playing = true;
                this.setState({
                   playPauseAudio: newPlayPauseAudio
                });
            }
            else{
                console.log('music is playing');
                // pause music
                this.audioEl.pause();
                // update UI
                const newPlayPauseAudio = {...this.state.playPauseAudio};
                newPlayPauseAudio.playing = false;
                this.setState({
                    playPauseAudio: newPlayPauseAudio
                });
            }
        }
        else{
             // play a different music
             console.log('different music');

             const that = this;

            // ajax call ----------------------------------------
            // const path = newFileUrl.split(kenaConfig.musicBucket)[1];
            const path = newFileUrl.split(config.bucket.musicBucket)[1];

            console.log('path: ', path);

            const ajaxData = {
                path: path,
                'stage': config.stage,
            }

            this.getAuthenticatedUser().getSession(function(err, session){
                if(err){
                    alert('error: session expired');
                }
                else{

                    console.log('ajaxdata: ', ajaxData);

                    axios({
                        url: config.apiGateway.downloadfile,
                        method: 'post',
                        data: JSON.stringify(ajaxData),
                        headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                    }).then(response =>{
                        console.log('success response: ', response);

                        // play audio player---------------------
                        
                        that.audioEl.src = response.data;
                        that.audioEl.play();
                        // update UI
                        const newPlayPauseAudio = {...that.state.playPauseAudio};
                        newPlayPauseAudio.musicId = newMusicId;
                        newPlayPauseAudio.fileUrl = newFileUrl;
                        newPlayPauseAudio.playing = true;

                        that.setState({
                            playPauseAudio: newPlayPauseAudio
                        });

                    }).catch(err =>{
                        console.log('err: ', err);
                    });
                }
            });
        }
    }

    checkAudioIcon = (musicId) =>{
        console.log('musicId: ', musicId);
        console.log('state musicId: ', this.state.playPauseAudio.musicId);
        console.log('state plying: ', this.state.playPauseAudio.playing);
        if(this.state.playPauseAudio.musicId === musicId && this.state.playPauseAudio.playing){
            return 'pauseIcon';
        }
        else{
            return 'playIcon'
        }
    }

    descriptionChangeHandler = (ev, musicId, type) =>{
        const val = ev.target.value;

        // find index
        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // copy object
        const copiedMusic = {...this.state.myMusic[index]};

        if( type === 'description'){
            copiedMusic.music_description = val;
        }
        else if(type === 'title'){
            copiedMusic.music_title = val;
        }
        
        // update data
        const myMusic = [...this.state.myMusic];
        myMusic[index] = copiedMusic;

        this.setState({
            myMusic: myMusic
        });

    }

    descriptionBlurredHandler = (musicId) =>{
        // find indes
        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // update db
        const ajaxUrl = config.apiGateway.update_music_info;

        const ajaxData = {
            "user_id": this.state.userId,
            "music_id": musicId,
            "modified_at": Date.now(),
            "music_title": this.state.myMusic[index].music_title,
            // "music_genre" : myMusic[musicIndex].music_genre,
            // "music_instrument" : myMusic[musicIndex].music_instrument,
            "music_description" : this.state.myMusic[index].music_description,
            'stage': config.stage,
        }

        console.log('ajaxData: ', ajaxData);

        this.updateDatabase(ajaxData, ajaxUrl);
    }


    updateDatabase = (ajaxData, ajaxUrl) =>{
        console.log('ajaxData: ', ajaxData);
        console.log('ajaxUrl: ', ajaxUrl);

        // get token
        this.getAuthenticatedUser().getSession(function(err, session){
            if(err){
                alert('error: session expired');
            }
            else{            
                axios({
                    url: ajaxUrl,
                    method: 'post',
                    data: ajaxData,
                    headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                }).then(response =>{
                    console.log('success response: ', response);
                }).catch(err =>{
                    console.log('err: ', err);
                });
            }
        });
    }

    deleteMusicHandler = (musicId) =>{
        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        console.log('index: ', index);
        console.log('musicId: ', musicId);

        // update db
        const ajaxUrl = config.apiGateway.delete_music;

        const ajaxData = {
            user_id: this.state.userId,
            music_id: musicId,
            music_status: this.state.myMusic[index].music_status,
            music_genre_id: this.state.myMusic[index].music_genre_id,
            'stage': config.stage,
        }

        console.log('ajaxData: ', ajaxData);
        console.log('ajaxUrl: ', ajaxUrl);

        this.updateDatabase(ajaxData, ajaxUrl);

        // update UI
        const updatedData = produce(this.state.myMusic, draft =>{
            draft.splice(index, 1);
        });

        this.setState({
            myMusic: updatedData
        });

    }
  
    genreChangeHandler = (value, musicId) =>{
        console.log('value: ', value);
        console.log('musicId: ', musicId);

        // 2. value is obj, need to convert obj to array to save
        let objValueToArray = []; // empty
        if(value){
            objValueToArray = value.map(obj=>{
                return obj.value;
            });
        }

        console.log('objValueToArray', objValueToArray);

        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // update ui
        const updatedData = produce(this.state.myMusic, draft =>{
            draft[index].music_genre = objValueToArray;
        });

        this.setState({
            myMusic: updatedData
        });
        
    }

    diagnosticsButtonClickHandler = (ev, musicId) =>{
        ev.preventDefault();
        console.log('diag button clicked');
        console.log('musicId: ', musicId);

        const myPassword = 'fujiappletastgreat';

        const encryptedUserId = CryptoJS.AES.encrypt(this.state.userId, myPassword);
        const encryptedMusicId = CryptoJS.AES.encrypt(musicId, myPassword);
        
        const musicData = {
            'userId': encryptedUserId.toString(),
            'authorId': encryptedUserId.toString(),
            "musicId": encryptedMusicId.toString(),
        }

        localStorage.setItem('kenaMusicData', JSON.stringify(musicData));

        // console.log('redirect to diagnostics page');
        window.location.href = './diagnostics';

    }

    render(){
        console.log('======================', this.state);

        let loading = null;
        if(this.state.loadingIcon){
            loading = <div className='loading'>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        color='#4f4f4f'
                        size='6x'
                        spin/>
                </div>
        }

        let musicList = null;
        if(this.state.myMusic.length){
            musicList = this.state.myMusic.map((val)=>{
                return <Music
                        key={val.music_id}
                        diagnostics_status={val.diagnostics_status}
                        music_id={val.music_id}
                        music_satatus={val.music_satatus}
                        music_url={val.music_url}
                        music_title={val.music_title}
                        music_instrument={val.music_instrument}
                        music_duration={val.music_duration}
                        modified_at={val.modified_at}
                        published={val.published}
                        music_genre={val.music_genre}
                        music_description={val.music_description}
                        kena_score={val.kena_score}
                        clicked={this.playPauseAudio}
                        audioIcon={this.checkAudioIcon(val.music_id)}
                        titleAndDescriptionChanged={this.descriptionChangeHandler}
                        titleAndDescriptionBlurred={this.descriptionBlurredHandler}
                        deleteMusicClicked={this.deleteMusicHandler}
                        music_genre_id={this.state.genreEtc.genre.music_genre_id}
                        genreChanged={this.genreChangeHandler}
                        diagnosticsButtonClicked={this.diagnosticsButtonClickHandler}/>
            })
        }
    
        return (
            <div className='Library'>
                <section>
                    <div ref={node => {this.navbarNode = node;}}>
                        <Navbar
                            showMobileNavbar={this.state.showMobileNavbar}
                            clicked={this.toggleMobileNavbar}/>
                    </div>
                    
                    <div style={{paddingTop:120+'px'}}></div>

                    <SearchbarNav
                        changed={this.uploadMusicHandler}
                        uploadMusic1={this.state.uploadMusic1}
                        uploadMusic2={this.state.uploadMusic2}
                    />

                    {loading}

                    <h1 ref={node => {this.h1Node = node;}}>My Music</h1>

                    <p ref={node => {this.pNode = node}}>hdioahfio</p>
                    
                    {musicList}


                    
                  
                </section>
            </div>
        )
    }
}

export default Mymusic;