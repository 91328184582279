const configStage = 'test';

export default {
    stage: configStage,
    apiGateway: {
        // login page=========================================================
        'checkfirsttimelogin' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/checkfirsttimelogin',
        // my-music page======================================================
        // fetch existing music list for my-music
        'get_music_list' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/get-music-list',
        // Jin's API 
        'uploadfile' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/uploadfile',
        // Jin's API
        'downloadfile' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/downloadfile',
        // 
        'update_music_info' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/update-music-info',
        // 
        'delete_music' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/delete-music',
        // hear beat
        'check_diagnostics_complete' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/check-diagnostics-complete',
        // 
        'get_genre_etc': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/get-genre-etc',
        // save user to user table
        'save_user_to_user_table': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/save-user-to-user-table',
    
        // diagnostics page===================================================
        // 
        'get_music_diagnostics' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/get-music-diagnostics',
        // Jin's API
        'uploadcoverimage' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/uploadcoverimage',
        // publish to library
        'publish_to_library' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/publish-to-library',
        // check user display name availability
        'backend_name_check' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/backend-name-check',
        
        // intro page==========================================================
        // Jin's API
        'uploadfile_first_time' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/uploadfile-first-time',
        
        // library page=========================================================
        'get_music_library' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/get-music-library',
        // get total likes
        'total_likes' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/total-likes',
        // toggle likes
        'put_music_like' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/put-music-like',
        'delete_music_like' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/delete-music-like',

        // Profile page==========================================================
        'get_user_profile' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/get-user-profile',
        'update_user_profile': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/update-user-profile',
        'profile_image_upload': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/profile-image-upload',
        'backend_name_check' : 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/-backend-name-check',
        
        'backend_unpublish_from_library': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/backend-unpublish-from-library',
        'backend_edit_published_notes': 'https://asa8ggh6ie.execute-api.us-east-1.amazonaws.com/' + configStage + '/backend-edit-published-notes',
    },
    bucket: {
        // bucket path=======================================================
        'musicBucket' : 'https://s3.amazonaws.com/' + configStage + '.kena.ai.music/',
    },
    pool_data: {
        UserPoolId: 'us-east-1_Dr5cy3Pfm', // Your user pool id here
        ClientId: '41141jgjj72rot3j3a4a6fu4jo', // Your client id here
    }
}