import React, {Component} from 'react';
import './Diagnostics.css';
import kenaLogo from '../../assets/logo.png';
import config from '../../config';
import axios from 'axios';
import { 
    CognitoUserPool, 
    AuthenticationDetails, 
    CognitoUser,
    CognitoUserSession,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import bgImage from '../../assets/bg2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import produce from 'immer';
import CryptoJS from 'crypto-js';
import Chart from 'chart.js';

import Navbar from '../../componentes/Navbar/Navbar';
import Input from '../../componentes/Input/Input';
import Button from '../../componentes/Button/Button';
import SearchbarNav from '../../componentes/SearchbarNav/SearchbarNav';
import Music from '../../componentes/Music/Music';
import Tabs from '../../componentes/Tabs/Tabs';

class Diagnostics extends Component{

    // constructor(props){
    //     super(props);
    //     this.earlyLateChart = React.createRef();
    // }
    earlyLateChart = React.createRef();
    chartRef2 = React.createRef();

    state={
        userId: '',
        data: {},
        uploadMusic1: 'Upload Music',
        uploadMusic2: 'Upload Music',
        loadingIcon: false,
        showMobileNavbar: false,
        playPauseAudio: {
            musicId: null,
            fileUrl: null,
            playing: false,
        },
        activeTab: 'Diagnostics',
        rhythmChartX : [],
        rhythmChartY : [],
    }

    componentDidMount(){
        // const ajaxData = {
        //     "user_id": userId,
        //     'page_no': 0,
        //     'stage': config.stage,
        // }

        // axios({
        //     url: config.apiGateway.get_music_list,
        //     method: 'post',
        //     data: ajaxData,
        //     headers: {'Authorization': session.getIdToken().getJwtToken()}, 
        // }).then(response =>{
        // console.log('success response: ', response);
        // }).catch(err =>{
        //     console.log('err: ', err);
        // });

        document.addEventListener('mousedown', this.handleOutsideClick);
        
        const poolData = {
            UserPoolId: config.pool_data.UserPoolId, // Your user pool id here
            ClientId: config.pool_data.ClientId, // Your client id here
        };

        var userPool = new CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();

        console.log('cognito user: ', cognitoUser);

        if (cognitoUser != null) {

            // save userid
            const userId = cognitoUser.username;
            this.setState({
                userId: userId
            });

            // get token
            this.getAuthenticatedUser().getSession(function(err, session){
                if(err){
                    alert('error: session expired');
                }
                else{
                    // get genre etc-----------------------------------------------------------
                    const ajaxDataGetGenreEtc = {
                        'stage': config.stage,
                    }

                    axios({
                        url: config.apiGateway.get_genre_etc,
                        method: 'post',
                        data: JSON.stringify(ajaxDataGetGenreEtc),
                        contentType: "application/json",
                        headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                    }).then(response =>{
                        console.log('succeeded get gereEtc');  
                        console.log('response: ', response);

                        const genreEtc = {};
                        genreEtc.musicScale = JSON.parse(response.data.musicScale);
                        genreEtc.genre = JSON.parse(response.data.genre);
                        genreEtc.instrument = JSON.parse(response.data.instrument);
                        genreEtc.mood = JSON.parse(response.data.mood);
                        genreEtc.musicKey = JSON.parse(response.data.musicKey);
                        console.log('genreEtc: ', genreEtc);

                        this.setState({
                            genreEtc: genreEtc
                        });

                        // get local storage!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        if(localStorage.getItem('kenaMusicData') !== null){
                            const localStorageData = JSON.parse(localStorage.getItem('kenaMusicData'));

                            // console.log('encrypteduserId: ', encrypteduserId);
                            const myPassword = 'fujiappletastgreat';    
                            const bytesUserId = CryptoJS.AES.decrypt(localStorageData.userId, myPassword);
                            const bytesAuthorId = CryptoJS.AES.decrypt(localStorageData.authorId, myPassword);
                            const bytesMusicId = CryptoJS.AES.decrypt(localStorageData.musicId, myPassword);
                            const decryptedUserId = bytesUserId.toString(CryptoJS.enc.Utf8);
                            const decryptedAuthorId = bytesAuthorId.toString(CryptoJS.enc.Utf8);
                            const decryptedMusicId = bytesMusicId.toString(CryptoJS.enc.Utf8);
    
                            const ajaxData = {
                                user_id : decryptedUserId,
                                music_author_id : decryptedAuthorId,
                                music_id: decryptedMusicId,
                                stage: config.stage,
                            }

                            console.log('ajaxData: ', ajaxData);

                            // check if viewer is author-------------------------------
                            let isViewerIsAuthor = '';
                                
                            if(ajaxData.user_id == ajaxData.music_author_id){
                                isViewerIsAuthor = true;
                            }

                            console.log('isViewerIsAuthor: ', isViewerIsAuthor);

                            console.log(' get music ajaxData: ', ajaxData);
    
                            // check email
                            if(this.checkLoggedInemailIsSameAsSendingRequest(ajaxData.user_id)){
                                
                                axios({
                                    url: config.apiGateway.get_music_diagnostics,
                                    method: 'post',
                                    data: ajaxData,
                                    headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                                }).then(response =>{
                                    console.log('success response: ', response.data);
                                    console.log('parsed response: ', JSON.parse(response.data));

                                    const data = JSON.parse(response.data);
                                    this.setState({
                                        data: data
                                    });

                                    // render early/late chart----------------------------------
                                    // round up beat_times data
                                    const rhythmChartX = []; // round up 2.01 is good
                                    const rhythmChartY = data.errors;
                                    
                                    for(let i=0; i<data.beat_times.length; i++){
                                        rhythmChartX.push(data.beat_times[i].toFixed(2));
                                    }

                                    this.setState({
                                        rhythmChartX: rhythmChartX,
                                        rhythmChartY: rhythmChartY
                                    })

                                    this.createEarlyLateChart(rhythmChartX, rhythmChartY);

                                }).catch(err =>{
                                    console.log('err: ', err);
                                });
                            }
                        }
                    }).catch(err =>{
                        console.log('err: ', err);
                    });
                   
                }
            }.bind(this));

        }
        else{
            window.location.href = './index.html?v=17';
        }
    }

    componentDidUpdate(){
        // for chart
        if(this.state.activeTab === 'Diagnostics'){
            /* 
               Because tab will hide (delete?) elements inside of it, we need to re-initialize when it re-appear.
               We need to make sure render() had occurred before searching for char ref
               so use componentDidMount() or componentDidUpdate() to run the function.
            */
            this.createEarlyLateChart(this.state.rhythmChartX, this.state.rhythmChartY);
        }
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleOutsideClick);
    } 

    handleOutsideClick = (e) =>{
        // clicked h1?
        if(this.navbarNode.contains(e.target)){
            
        }
        else{
            this.setState({
                showMobileNavbar: false
            });
        }
    }

    getAuthenticatedUser = () =>{
        const poolData = {
            UserPoolId: config.pool_data.UserPoolId, // Your user pool id here
            ClientId: config.pool_data.ClientId, // Your client id here
        };

        var userPool = new CognitoUserPool(poolData);
        return userPool.getCurrentUser();
    }

    checkLoggedInemailIsSameAsSendingRequest = (snedingEmail) =>{
        // console.log('sendingmeial: ', snedingEmail);
        const userId = this.state.userId;
        // console.log('userid: ', userId);
        if(userId == snedingEmail){
            return true;
        }
        else{
            return false;
        }
    }

    uploadMusicHandler = (ev) =>{
        console.log('type: ', ev.target.type);
        if(ev.target.type && ev.target.type === 'file'){
            // display file name when file was selected
            const label = ev.target.nextElementSibling;
            const labelVal = label.innerHTML;

            let fileName = '';
            if(ev.target.files && ev.target.files.length > 1){
                fileName = (ev.target.getAttribute('data-multiple.caption') || '').replace('{count}', ev.target.files.length);
            }
            else{
                fileName = ev.target.value.split('\\').pop();

                console.log('e target files[0]: ', ev.target.files[0]);
                console.log('file name: ', ev.target.files[0].name);
                console.log('file type: ', ev.target.files[0].type);
                console.log('file size: ', ev.target.files[0].size/1024/1024 + 'MB');
            }

            if(fileName){
                const span = ev.target.name +1;
                console.log('span state: ', span);
                this.setState({ [span]: fileName });

                // file extension and size vertification
                const fileNameSplit =  ev.target.files[0].name.split('.');
                let fileExtension = fileNameSplit[fileNameSplit.length -1];
                fileExtension = fileExtension.toLowerCase();
                const fileSize = Math.round(ev.target.files[0].size/1024/1024);

                const userId = this.state.userId;
                console.log('ext, size: ', fileExtension + ", ", fileSize);
                console.log('filenamesplit[0]: ', fileNameSplit[0]);
                console.log('userid: ', userId);

                let isAcceptableExtension = null;
                if(fileExtension == "mp3" || fileExtension == "m4a" || fileExtension == "ogg" || fileExtension == "wav"){
                    isAcceptableExtension = true;
                }
                else{
                    // if wong extansion
                    alert('Max file size : 2MB. Supported extensions: mp3, m4a, ogg, wav');
                }

                // check file extension and size (2MB)
                if(isAcceptableExtension && fileSize < 2){
                    console.log('file size and ext is good');

                    // loading icon------------------------------------
                    this.setState({
                        loadingIcon: true,
                    });

                    // base64--------------------------------------------
                    // testing api post
                    let userData = {
                        'userId': userId,
                        'fileName': fileNameSplit[0],
                        'created_at': Date.now(),   // UTC timestamp in milliseconds
                        'stage': config.stage,
                    };

                    const userFile = ev.target.files[0];

                    console.log('userdata: ', userData);

                    const that = this;

                    // get binary string-----------------------------------------
                    (function handleFileSelect(evt) {
                        var f = userFile; // FileList object
                        var reader = new FileReader();
                        // Closure to capture the file information.
                        reader.onload = (function(theFile) {
                            return function(e) {                                       

                                var binaryData = e.target.result;
                                //Converting Binary Data to base 64
                                var base64String = window.btoa(binaryData);
                                userData.user_file = base64String;

                                console.log('base64string: ', base64String);

                                // get token--------------------------------------------------
                                console.log('that', that);
                                that.getAuthenticatedUser().getSession(function(err, session){
                                    if(err){
                                        alert('error: session expired');
                                    }
                                    else{

                                        // check email
                                        if(that.checkLoggedInemailIsSameAsSendingRequest(userData.userId)){

                                             axios({
                                                // url: config.apiGateway.uploadfile,
                                                method: 'post',
                                                data: JSON.stringify(userData),
                                                contentType: 'application/json',
                                                headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                                            }).then(response =>{
                                                console.log('success response: ', response);

                                            }).catch(err =>{
                                                console.log('err: ', err);
                                            });

                                        }
                                    }
                                })

                            };
                        })(f);
                        // Read in the image file as a data URL.
                        reader.readAsBinaryString(f);
                    })();

                }
            }
            else{
                const label = ev.target.name +2;
                this.setState({[label]:labelVal});
            }
        }
    }

    audioEl = document.createElement('audio');

    playPauseAudio = (newMusicId, newFileUrl) =>{
        // let currentPlayingId = null;

        console.log('test');
        console.log('newMusicId: ', newMusicId);
        console.log('old musicid: ', this.state.playPauseAudio.musicId);
        console.log('newFileUrl: ', newFileUrl);

        // check if user wants to play different music
        if(this.state.playPauseAudio.musicId === newMusicId){
            console.log('same music id');
            console.log('t f: ', this.audioEl.paused);
            if(this.audioEl.paused){
                console.log('music paused');
                // play music
                this.audioEl.play();
                // update UI
                const newPlayPauseAudio = {...this.state.playPauseAudio};
                newPlayPauseAudio.playing = true;
                this.setState({
                   playPauseAudio: newPlayPauseAudio
                });
            }
            else{
                console.log('music is playing');
                // pause music
                this.audioEl.pause();
                // update UI
                const newPlayPauseAudio = {...this.state.playPauseAudio};
                newPlayPauseAudio.playing = false;
                this.setState({
                    playPauseAudio: newPlayPauseAudio
                });
            }
        }
        else{
             // play a different music
             console.log('different music');

             const that = this;

            // ajax call ----------------------------------------
            // const path = newFileUrl.split(kenaConfig.musicBucket)[1];
            const path = newFileUrl.split(config.bucket.musicBucket)[1];

            console.log('path: ', path);

            const ajaxData = {
                path: path,
                'stage': config.stage,
            }

            this.getAuthenticatedUser().getSession(function(err, session){
                if(err){
                    alert('error: session expired');
                }
                else{

                    console.log('ajaxdata: ', ajaxData);

                    axios({
                        url: config.apiGateway.downloadfile,
                        method: 'post',
                        data: JSON.stringify(ajaxData),
                        headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                    }).then(response =>{
                        console.log('success response: ', response);

                        // play audio player---------------------
                        
                        that.audioEl.src = response.data;
                        that.audioEl.play();
                        // update UI
                        const newPlayPauseAudio = {...that.state.playPauseAudio};
                        newPlayPauseAudio.musicId = newMusicId;
                        newPlayPauseAudio.fileUrl = newFileUrl;
                        newPlayPauseAudio.playing = true;

                        that.setState({
                            playPauseAudio: newPlayPauseAudio
                        });

                    }).catch(err =>{
                        console.log('err: ', err);
                    });
                }
            });
        }
    }

    checkAudioIcon = (musicId) =>{
        console.log('musicId: ', musicId);
        console.log('state musicId: ', this.state.playPauseAudio.musicId);
        console.log('state plying: ', this.state.playPauseAudio.playing);
        if(this.state.playPauseAudio.musicId === musicId && this.state.playPauseAudio.playing){
            return 'pauseIcon';
        }
        else{
            return 'playIcon'
        }
    }

    descriptionChangeHandler = (ev, musicId, type) =>{
        const val = ev.target.value;

        // find index
        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // copy object
        const copiedMusic = {...this.state.myMusic[index]};

        if( type === 'description'){
            copiedMusic.music_description = val;
        }
        else if(type === 'title'){
            copiedMusic.music_title = val;
        }
        
        // update data
        const myMusic = [...this.state.myMusic];
        myMusic[index] = copiedMusic;

        this.setState({
            myMusic: myMusic
        });

    }

    descriptionBlurredHandler = (musicId) =>{
        // find indes
        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // update db
        const ajaxUrl = config.apiGateway.update_music_info;

        const ajaxData = {
            "user_id": this.state.userId,
            "music_id": musicId,
            "modified_at": Date.now(),
            "music_title": this.state.myMusic[index].music_title,
            // "music_genre" : myMusic[musicIndex].music_genre,
            // "music_instrument" : myMusic[musicIndex].music_instrument,
            "music_description" : this.state.myMusic[index].music_description,
            'stage': config.stage,
        }

        console.log('ajaxData: ', ajaxData);

        this.updateDatabase(ajaxData, ajaxUrl);
    }


    updateDatabase = (ajaxData, ajaxUrl) =>{
        console.log('ajaxData: ', ajaxData);
        console.log('ajaxUrl: ', ajaxUrl);

        // get token
        this.getAuthenticatedUser().getSession(function(err, session){
            if(err){
                alert('error: session expired');
            }
            else{            
                axios({
                    url: ajaxUrl,
                    method: 'post',
                    data: ajaxData,
                    headers: {'Authorization': session.getIdToken().getJwtToken()}, 
                }).then(response =>{
                    console.log('success response: ', response);
                }).catch(err =>{
                    console.log('err: ', err);
                });
            }
        });
    }

  
    genreChangeHandler = (value, musicId) =>{
        console.log('value: ', value);
        console.log('musicId: ', musicId);

        // 2. value is obj, need to convert obj to array to save
        let objValueToArray = []; // empty
        if(value){
            objValueToArray = value.map(obj=>{
                return obj.value;
            });
        }

        console.log('objValueToArray', objValueToArray);

        const index = this.state.myMusic.findIndex(val=>{
            return val.music_id === musicId;
        });

        // update ui
        const updatedData = produce(this.state.myMusic, draft =>{
            draft[index].music_genre = objValueToArray;
        });

        this.setState({
            myMusic: updatedData
        });
        
    }

    tabButtonClicked = (name) =>{
        console.log('name', name);
        this.setState({activeTab: name});
    }

    testFunc = () =>{
        console.log('test func');
    }

    createEarlyLateChart = (rhythmChartX, rhythmChartY) =>{

        // chart for rhythm analysis
        // calculate color
        const rhythmBackgroundColor = [];
        for(let i=0; i<rhythmChartY.length; i++){
            if(rhythmChartY[i] < 0.25){
                rhythmBackgroundColor.push('rgba(230, 166, 166, 1)');
            }
            else if(rhythmChartY[i] < 0.5){
                rhythmBackgroundColor.push('rgba(230, 166, 166, 1)');
            }
            else{
                rhythmBackgroundColor.push('rgba(230, 166, 166, 1)');
            }
        }

        // anchor for plugin
        const rhythmAnchor = [];
        for(let i=0; i<rhythmChartY.length; i++){
            if(rhythmChartY[i] < 0){
                rhythmAnchor.push('end');
            }
            else{
                rhythmAnchor.push('start');
            }
        }

       
        const earlyLateChartRef = this.earlyLateChart.current.getContext("2d");
  
        new Chart(earlyLateChartRef, {
            type: 'bar',
            data: {
                labels: rhythmChartX,
                datasets: [{
                    label: 'error',
                    data: rhythmChartY,
                    backgroundColor: rhythmBackgroundColor,
                    // borderColor: [
                    //     'rgba(89, 89, 89, 1)',
                    //     'rgba(89, 89, 89, 1)',
                    // ],
                    borderWidth: 1,
                }]
            },
            options: {
                // responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            fontColor: 'black',
                            min: -100,
                            max: 100,
                            callback: function(val, index, values){
                                return val + '%';
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false,
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'seconds'
                        },
                        ticks: {
                            display: true,
                            beginAtZero: false,
                            fontColor: 'black',
                            callback: function(val, index, values){
                                return val + 's';
                            }
                        }
                    }]
                },
                legend: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        color: 'gray',
                        align: 'center',
                        anchor: rhythmAnchor,
                        formatter: function(value, context){
                            // console.log('value : ', value);
                            // console.log('context: ', context);
                            return '';
                        }
                    }
                }
            }
        });

        //
        const chartRef2 = this.chartRef2.current.getContext("2d");

        new Chart(chartRef2, {
            type: "line",
            data: {
                //Bring in data
                labels: ["Jan", "Feb", "March"],
                datasets: [
                    {
                        label: "Sales",
                        data: [86, 67, 91],
                    }
                ]
            },
            options: {
                //Customize chart options
            }
        });

    }

    toggleMobileNavbar = () =>{
        this.setState({
            showMobileNavbar: !this.state.showMobileNavbar
        });
    }

    changeChartHandler = () =>{
        this.setState({
            rhythmChartX: ["Jan", "Feb", "March"],
            rhythmChartY: [44,55,11]
        })
    }


    render(){
        console.log('======================', this.state);

        let loading = null;
        if(this.state.loadingIcon){
            loading = <div className='loading'>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        color='#4f4f4f'
                        size='6x'
                        spin/>
                </div>
        }

    
        return (
            <div className='Diagnostics'>
                <section>
                    <div ref={node => {this.navbarNode = node;}}>
                        <Navbar
                            showMobileNavbar={this.state.showMobileNavbar}
                            clicked={this.toggleMobileNavbar}/>
                    </div>
                   

                    <div style={{paddingTop:120+'px'}}></div>

                    <SearchbarNav
                        changed={this.uploadMusicHandler}
                        uploadMusic1={this.state.uploadMusic1}
                        uploadMusic2={this.state.uploadMusic2}/>

                    {loading}

                    <h1>{this.state.data.music_title}</h1>

                    {/* tabs------------------------------------------- */}
                    <Tabs
                        activeTab={this.state.activeTab}
                        clicked={this.tabButtonClicked}>
                        
                        {/* use this tabcontent to create buttons */}
                        <div id='Diagnostics' className='tabContent'>
                            <p>Diagnostics</p>
                            <p>lorem jio ioj</p>

                            <div style={{overflowX:'auto'}}>
                                <div style={{width:500+'px', height:300+'px'}}>
                                    <canvas
                                        id='earlyLateChart'
                                        ref={this.earlyLateChart}
                                    />
                                </div>
                            </div>

                            <button type='button' onClick={this.changeChartHandler}>change chart</button>
                            
                            <div style={{overflowX:'auto'}}>
                                <div style={{width:500+'px', height:300+'px'}}>
                                    <canvas
                                        id='differentChart'
                                        ref={this.chartRef2}
                                    />
                                </div>
                            </div>
                            
                            <p>jopodpj fajdp fjsioa fjdiosaj</p>

                        </div>

                        <div id='Score Sheet' className='tabContent'>
                            <p>Score Sheet</p>
                            <p>dsa joia j</p>
                        </div>

                        <div id='WorkStation' className='tabContent'>
                            <p>workStation</p>
                            <p>a sjiod</p>
                        </div>
                    </Tabs>

                    

                   

                  
                </section>
            </div>
        )
    }
}

export default Diagnostics;