import React from 'react';
import './Music.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const music = (props) =>{

    // convert duration==========================================
    const duration = moment.duration(props.music_duration, 'seconds');

    let time = '';
    const hours = duration.hours();
    if(hours > 0){
        time = hours + ':';
    }

    let seconds = duration.seconds();
    if(seconds <10){
        seconds = '0' + seconds;
    }
    time = time + duration.minutes()  + ':' + seconds;
    // end convert duration==========================================


    // convert unix time=============================================
    const convertedTime = moment(props.modified_at, 'x').format('MMM DD,YYYY');
    // end convert unix time=============================================
    

    // check audio icon=================================================
    let playPauseIcon = 'P';
    if(props.audioIcon === 'playIcon'){
        playPauseIcon = <FontAwesomeIcon
                            icon={faPlay}
                            color='#4f4f4f'
                            size='1x'/>;
    }
    else if(props.audioIcon === 'pauseIcon'){
        playPauseIcon = <FontAwesomeIcon
                            icon={faPause}
                            color='#4f4f4f'
                            size='1x'/>;
    }
    // end check audio icon=================================================


    // genre options========================================================
    let genreOptions = props.music_genre_id.map(val=>{
        return <option key={val} value={val}>{val}</option>;
    });
    // end genre options========================================================

    // create genre options for Select component========================================================
    let selectGenreOptions = [];
    props.music_genre_id.map(val=>{
        selectGenreOptions.push({
            value: val, label: val
        })
    });
    // end create genre options Select component========================================================

    // create default value for Select component==================================================
    // 1. need to convert array to defaultValue={[colourOptions[2], colourOptions[3]]}
    let selectDefaultValue = [];
    props.music_genre.map(val=>{
        props.music_genre_id.map((genreId, i)=>{
            if(val === genreId){
                selectDefaultValue.push(selectGenreOptions[i]);
            }
        })
    });
    // end create default value for Select component==================================================

    // check if value need to covert from array to {value:'Piano', label:'Piano}===================
    // let selectValue = [];
    // console.log('props.music_genre[0].label: ', props.music_genre[0].label);
    // if(!props.music_genre[0].label){
    //     props.music_genre.map(val=>{
    //         selectValue.push({
    //             value: val, label: val
    //         })
    //     })
    // }
    // else{
    //     selectValue = props.music_genre;
    // }
    // console.log('selectValue: ', selectValue);
    // end check if value need to covert from array to {value:'Piano', label:'Piano}===================


    return(
        <div className='Music'>
            <div className='disp-flex list-item'>
                <div className='playButtonDiv'>
                    <button onClick={props.clicked.bind(this, props.music_id, props.music_url)} type='button' className='btn playButton'>{playPauseIcon}</button>
                    <button type='button' className='trashIconButton' onClick={props.deleteMusicClicked.bind(this, props.music_id)}>T</button>
                </div>
                <div className='width-100 disp-flex justify-content--space-between'>
                    <div style={{width: 500+'px'}}>
                    <div><input onChange={(ev)=>props.titleAndDescriptionChanged(ev, props.music_id, 'title')} type="text" className='title' value={props.music_title} onBlur={props.titleAndDescriptionBlurred.bind(this, props.music_id, 'title')}/>  &nbsp;&nbsp; <span className='duration'>Duration {time}</span> <span className='createdAt'> / {convertedTime}</span> <span className='publishedStatus'>P?</span></div>
                        <div style={{padding: 10+'px'+ 0}}><span className='status'>{props.status}</span> / <span className='instrument'>{props.music_instrument}</span><span hidden><select className='hiddenInstrumentSelect select2El'>
                            {/* ${renderInstrumentOptions(genreEtc.instrument.instruments, myMusic[i].music_instrument)} */}
                        </select></span> / 
                            <span className='genre'>{props.music_genre}</span><span ><select className='hiddenGenreSelect select2El' multiple>
                            {genreOptions}
                        </select></span>
                        <Select 
                            defaultValue={selectDefaultValue}
                            isMulti
                            isClearable={false}
                            // value={selectValue}
                            options={selectGenreOptions}
                            onChange={(value)=>props.genreChanged(value, props.music_id)}
                        />
                        </div>
                        <textarea onChange={(ev)=>props.titleAndDescriptionChanged(ev, props.music_id, 'description')} onBlur={props.titleAndDescriptionBlurred.bind(this, props.music_id, 'description')} value={props.music_description} className='hiddenNoteTextarea' rows='4'></textarea>
                    </div>
                    <div style={{width: 150+'px'}} className='rightDiv'>
                        <a onClick={(ev)=>props.diagnosticsButtonClicked(ev, props.music_id)} className='btn linkDiagnose' href="#">Diagnose</a>
                        <div></div>
                        <div className='disp-flex justify-content--space-between'><span className='kenaScoreTitle'>Kena Score</span><span className='kenaScore'>{Math.round(props.kena_score)}</span></div>
                    </div>
                </div>
            </div>
            {/* <div class='disp-flex list-item' data-id='${myMusic[i].music_id}' data-music-status='${myMusic[i].music_status}'>
                <div class='playButtonDiv'>
                    <button type='button' id='playButton${myMusic[i].music_id}' class='btn playButton' data-file-url='${myMusic[i].music_url}' data-isPlaying='false'><i class="fas fa-play" style="font-size: 14px"></i></button>
                    <button type='button' class='trashIconButton'><i class="fas fa-trash trashIcon"></i></button>
                </div>
                <div class='width-100 disp-flex justify-content--space-between'>
                    <div style='width: 500px'>
                        <div><span class='title'>${myMusic[i].music_title}</span><input hidden type='text' class='hiddenTitleInput'/> &nbsp;&nbsp; <span class='duration'>Duration ${convertSeconds(myMusic[i].music_duration)}</span> <span class='createdAt'> / ${convertUnixTime(myMusic[i].modified_at)}</span> <span class='publishedStatus'>${checkIfPublished(myMusic[i].published)}</span></div>
                        <div style='padding: 10px 0'><span class='status'>${myMusic[i].music_status}</span> / <span class='instrument'>${checkInstrumentNone(myMusic[i].music_instrument)}</span><span hidden><select class='hiddenInstrumentSelect select2El'>
                            ${renderInstrumentOptions(genreEtc.instrument.instruments, myMusic[i].music_instrument)}
                        </select></span> / 
                        <span class='genre'>${renderGenre(myMusic[i].music_genre)}</span><span hidden><select class='hiddenGenreSelect select2El' multiple>
                            ${renderGenreOptions(genreEtc.genre.music_genre_id)}
                        </select></span></div>
                        <div class='note'>${truncateNote(myMusic[i].music_description)}</div><textarea class='hiddenNoteTextarea' rows='4'></textarea>
                    </div>
                    <div style='width: 150px' class='rightDiv'>
                        <a class='btn linkDiagnose' href="#">Diagnose</a>
                        <div id='score${myMusic[i].music_id}'></div>
                        <div class='disp-flex justify-content--space-between'><span class='kenaScoreTitle'>Kena Score</span><span class='kenaScore'>${Math.round(myMusic[i].kena_score)}</span></div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default music;