import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Mymusic from './pages/Mymusic/Mymusic';
import Diagnostics from './pages/Diagnostics/Diagnostics';
import Library from './pages/Library/Library';

class App extends Component {

  render() {
    return (
        <BrowserRouter>
            <div className="App">
            <Route path='/' exact component={Login}/>
            <Route path='/signup' exact component={Signup}/>
            <Route path='/my-music' exact component={Mymusic}/>
            <Route path='/diagnostics' exact component={Diagnostics}/>
            <Route path='/library' exact component={Library}/>
            </div>
        </BrowserRouter>
    );
  }
}

export default App;
