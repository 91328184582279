import React from 'react';
import './Tabs.css';

const Tabs = (props) =>{


    // create buttons
    let buttons = '';
    buttons = props.children.map((val, i)=>{
        // *val is a child and can access id using props
        // console.log('val', val);  
        return <button
            type='button'
            className='tablinks'
            key={i}
            onClick={props.clicked.bind(this, val.props.id)}>{val.props.id}</button>
    });

    return(
        <div className='Tabs'>
            {buttons}

            {/* 
                loop through children div then access props.id using React.Children.map
                it is basically same as array.map(val=>{})
                it doesn't work if there is single element in children
                Below method also works with props.children.map
            */}
            {React.Children.map(props.children, (child, i) =>{
                // console.log('activeTab', props.activeTab);
                // console.log('child id', child.props.id);
                
                if(props.activeTab === child.props.id){
                    return(
                        <div className='tabcontent'>
                            {child}
                        </div>
                    );
                }
            })}


        </div>
    )
}

export default Tabs;