import React from 'react';
import './Navbar.css';
import kenaLogo from '../../assets/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';


const navbar = (props) =>{

    let mobileNavbarStyle = {
        display: 'none'
    }
    if(props.showMobileNavbar){
        mobileNavbarStyle.display = 'block'
    }

    return (
        <div className='Navbar'>
            {/* desktop nav menu */}
            <div className='nav desktopNavContainer disp-flex align-items--center hide-tablet justify-content--spaceBetween'>
                <img src={kenaLogo} alt="logo" style={{width:100+'px'}}/>
            <div>
                <a href="https://www.kena.ai/" target="_blank">Home</a>
                <a href="https://www.kena.ai/press" target="_blank">Press</a>
                <a href="https://www.kena.ai/jobs" target="_blank">Jobs</a>
                <a href="https://www.kena.ai/culture" target="_blank">Culture</a>
                <a href="https://www.kena.ai/blog" target="_blank">Blog</a>
                <a href="#" className='logoutButton'>Logout</a>
            </div>

            </div>

            {/* mobile nav menu */}
            <div className='nav mobileNavContainer disp-flex align-items--center show-tablet-flex justify-content--end'>
                <div className="myNavLinks" style={mobileNavbarStyle}>
                    <a href="https://www.kena.ai/">Home</a>
                    <a href="./diagnostics" className='diagnosticsLink'>Workstation</a>
                    <a href="./my-music" className='myMusicLink'>My Music</a>
                    <a href="./library" className='libraryLink'>Library</a>
                    <a href="./profile" className='profileLink'>Profile</a>
                    <a href="#" className='logoutButton'>Logout</a>
                </div>
                <a onClick={props.clicked} href="#" className='navHamburgerIcon' aria-label="click to open mobile navigation menu">
                    <FontAwesomeIcon
                        icon={faBars}
                        color='black'
                        size='1x'/>
                </a>
            </div>
        </div>
    )
}

export default navbar;